import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Box, Button, HStack, Input, VStack } from "@chakra-ui/react";
import { Form } from "formik";
import React, { FC } from "react";
import FieldError from "./../../components/FormFieldError/FieldError";
import { InnerFormUserUpdateProps } from "./types";

export const InnerFormUserUpdate: FC<InnerFormUserUpdateProps> = ({
  values,
  errors,
  touched,
  isValid,
  isSubmitting,
  handleChange,
  handleSubmit,
  cancelHandler,
}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <VStack width="100%" spacing="4" alignItems="start">
        <Box w="100%" maxW="600px">
          <FormControl w="100%">
            <FormLabel>First Name</FormLabel>
            <Input
              name="firstName"
              w="100%"
              type="text"
              value={values.firstName}
              onChange={handleChange}
            />
            {errors.firstName && touched.firstName && (
              <FieldError>{errors.firstName}</FieldError>
            )}
          </FormControl>
          <FormControl w="100%" mt={4}>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              w="100%"
              type="text"
              value={values.lastName}
              onChange={handleChange}
            />
            {errors.lastName && touched.lastName && (
              <FieldError>{errors.lastName}</FieldError>
            )}
          </FormControl>
        </Box>
        <Box>
          <HStack>
            <Box>
              <Button
                colorScheme="cherryButton"
                type="submit"
                isLoading={isSubmitting}
                disabled={!isValid || isSubmitting}
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button onClick={cancelHandler} disabled={isSubmitting}>
                Cancel
              </Button>
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Form>
  );
};
