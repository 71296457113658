import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { ModalBody } from "@chakra-ui/react";
import React, { useCallback, useRef } from "react";
import { NewBusinessForm } from "../../forms/NewBusinessForm";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";

interface NewBusinessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const NewBusinessModal = ({
  isOpen,
  onClose,
}: NewBusinessModalProps) => {
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { showSuccessToast, showErrorToast } = useToast();
  const apiClient = useAdminApiClient();

  const onSuccess = useCallback(() => {
    showSuccessToast("New business created.");
    onClose();
  }, [showSuccessToast, onClose]);

  const onFailure = useCallback(() => {
    showErrorToast("Failed to create new business.");
  }, [showErrorToast]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={initialFocusRef}>
      <ModalOverlay></ModalOverlay>
      <ModalContent>
        <ModalContent>
          <ModalHeader>New Business</ModalHeader>
          <ModalBody>
            <NewBusinessForm
              onCancel={onClose}
              onFailure={onFailure}
              onSuccess={onSuccess}
              apiClient={apiClient}
              firstInputRef={initialFocusRef}
            />
          </ModalBody>
        </ModalContent>
      </ModalContent>
    </Modal>
  );
};
