import React from "react";
import {
  BookIcon,
  BookOpenIcon,
  CogIcon,
  PlatformApplicationsIcon,
  ColumnIcon,
  HomeIcon,
  UsersConfigurationIcon,
  MemberIcon,
  DownloadDocumentIcon,
  BriefcaseIcon,
  BackIcon,
  UsersIcon,
} from "../../styles/icons";
import { Accordion, Box, Divider, Text } from "@chakra-ui/react";
import {
  DesktopMenuItem,
  DesktopSubMenuItem,
  MobileMenuItem,
  MobileSubMenuItem,
} from "./HomeMenu";
import { useBusiness } from "../../context/BusinessContext";
import { useNavigate } from "react-router-dom";
import BusinessUtil from "../../util/BusinessUtil";

export const AdminMenu = ({ isDesktop }: { isDesktop: boolean }) => {
  const MenuItem = isDesktop ? DesktopMenuItem : MobileMenuItem;
  const SubMenuItem = isDesktop ? DesktopSubMenuItem : MobileSubMenuItem;
  const contextBusiness = useBusiness();
  const navigate = useNavigate();

  return (
    <Box w="100%">
      <Box mb={5}></Box>
      <Text
        ms={8}
        mt={5}
        mb={5}
        flex="1"
        fontWeight="bold"
        textOverflow="ellipsis"
        overflow="hidden"
        // whiteSpace="nowrap"
        color="#fff"
        fontSize="16px"
        cursor="pointer"
        // display="inline"
        borderBottom="1px solid #ccc"
        onClick={() => {
          if (contextBusiness) {
            navigate(BusinessUtil.getUrl(contextBusiness, "/"));
          } else {
            navigate("/");
          }
        }}
      >
        {contextBusiness?.srcBusiness?.name ?? contextBusiness?.title ?? "..."}
      </Text>
      <Box mb={5}></Box>
      <Accordion allowToggle width="100%">
        <MenuItem
          target={`/admin/businesses/${contextBusiness?._id}`}
          icon={<BriefcaseIcon boxSize="20px" />}
          title="Business"
        />

        {contextBusiness && !contextBusiness?.isSystemRecord && (
          <MenuItem
            target={`/admin/businesses/${contextBusiness?._id}/venues`}
            icon={<PlatformApplicationsIcon boxSize="20px" />}
            title="Venues"
          />
        )}

        {contextBusiness && contextBusiness?.isSystemRecord && (
          <MenuItem
            target={`/admin/businesses/${contextBusiness?._id}/apikeys`}
            icon={<PlatformApplicationsIcon boxSize="20px" />}
            title="API Keys"
          />
        )}

        <MenuItem
          target={`/admin/businesses/${contextBusiness?._id}/members`}
          icon={<UsersIcon boxSize="20px" />}
          title="Members"
        />

        <MenuItem
          target={`/admin/businesses/${contextBusiness?._id}/users`}
          icon={<UsersConfigurationIcon boxSize="20px" />}
          title="Users"
        />

        {/* <MenuItem
        target="/"
        icon={<PlatformApplicationsIcon boxSize="20px" />}
        title="Licensing"
      /> */}

        {contextBusiness && !contextBusiness?.isSystemRecord && (
          <MenuItem
            target={`/admin/businesses/${contextBusiness?._id}/settings`}
            icon={<CogIcon boxSize="20px" />}
            title="Settings"
          />
        )}

        <Divider my={3} />

        <MenuItem target="/" icon={<BackIcon boxSize="20px" />} title="Back" />

        {/* <MenuItem
        target="/admin/reports"
        icon={<BookIcon boxSize="20px" />}
        title="Reports"
      >
        <SubMenuItem
          icon={<DownloadDocumentIcon />}
          target="/admin/reports/status"
          title="Status"
        />
        <SubMenuItem
          icon={<BookOpenIcon />}
          target="/admin/reports/standard"
          title="Standard Reports"
        />
        <SubMenuItem
          icon={<ColumnIcon />}
          target="/admin/reports/comparative"
          title="Comparative Reports"
        />
      </MenuItem> */}
      </Accordion>
    </Box>
  );
};
