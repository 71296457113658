import { Box, VStack } from "@chakra-ui/layout";
import { HStack, Image } from "@chakra-ui/react";
import cherryLogo from "assets/ebet-logo.png";
import React, { useCallback, useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AppTitle } from "./components/AppTitle/AppTitle";
import { CurrentUser } from "./components/CurrentUser/CurrentUser";
import { ErrorHandler } from "./components/ErrorBoundary/ErrorBoundary";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";
import { Layout } from "./components/Layout/Layout";
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator";
import { AdminMenu } from "./components/Menu/AdminMenu";
import { HomeMenu } from "./components/Menu/HomeMenu";
import { BusinessContextProvider } from "./context/BusinessContext";
import { useFirebase } from "./context/FirebaseContext";
import { useLayoutContext } from "./context/LayoutContext";
import { ModelContextProvider } from "./context/ModelContext";
import { useUserContext } from "./context/UserContext";
import { ApikeyDetail } from "./pages/Apikeys/ApikeyDetail";
import { Apikeys } from "./pages/Apikeys/Apikeys";
import { BusinessDetail } from "./pages/Business/BusinessDetail";
import { Businesses } from "./pages/Business/Businesses";
import { ChangePassword } from "./pages/ChangePassword/ChangePassword";
import { Login } from "./pages/Login/Login";
import { MemberDetail } from "./pages/Members/MemberDetail";
import { Members } from "./pages/Members/Members";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import Sessions from "./pages/Sessions/Sessions";
import { Settings } from "./pages/Settings/Settings";
import { UserDetail } from "./pages/Users/UserDetail";
import { Users } from "./pages/Users/Users";
import { UserUpdate } from "./pages/Users/UserUpdate";
import { VenueDetail } from "./pages/Venues/VenueDetail";
import { Venues } from "./pages/Venues/Venues";

const getCurrentMenu = (businessId: string | undefined, pathName: string) => {
  const paths = pathName.split("/");
  const firstPath = (paths.length > 1 && paths[1].toLowerCase()) || "";
  if (businessId && businessId !== "new") {
    return AdminMenu;
  }
  return HomeMenu;
  // switch (firstPath) {
  //   case "data-api":
  //     return DataApiMenu;
  //   case "admin":
  //     return AdminMenu;
  //   case "":
  //   default:
  //     return HomeMenu;
  // }
};

const AppLayout = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { isDesktop } = useLayoutContext();
  const { businessId } = useParams();
  const AppMenu = getCurrentMenu(businessId, location.pathname);

  const { auth } = useFirebase();
  const navigate = useNavigate();

  const handleLogOut = () => {
    auth.signOut();
  };

  const handleBackButton = () => {
    if (window.location.pathname === "/") {
      auth.signOut();
    } else {
      navigate(-1);
    }
  };

  // useEffect(() => {
  //   if (window) {
  //     window.addEventListener("beforeunload", handleLogOut);
  //     window.addEventListener("popstate", handleBackButton);
  //   }

  //   return () => {
  //     if (window) {
  //       window.removeEventListener("beforeunload", handleLogOut);
  //       window.removeEventListener("popstate", handleBackButton);
  //     }
  //   };
  // }, []);

  return (
    <BusinessContextProvider>
      <Layout location={location.pathname}>
        <Layout.Title>
          <HStack>
            <AppTitle />
            {isDesktop && (
              <Box>
                <CurrentUser />
              </Box>
            )}
          </HStack>
        </Layout.Title>
        <Layout.Menu>
          {!isDesktop && (
            <HStack w="100%" bg="#fff" padding={2}>
              <Image src={cherryLogo} w="100px" title="Logo" />
              <CurrentUser />
            </HStack>
          )}
          {!!user && (
            <VStack spacing="2" w="100%" className="test">
              <AppMenu isDesktop={isDesktop} />
            </VStack>
          )}
        </Layout.Menu>
        <Layout.Content>
          <ErrorHandler
            renderErrorState={() => (
              <ErrorMessage>There was an error loading the page.</ErrorMessage>
            )}
          >
            <ModelContextProvider>
              <Outlet />
            </ModelContextProvider>
          </ErrorHandler>
        </Layout.Content>
      </Layout>
    </BusinessContextProvider>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, isLoading } = useUserContext();

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!user) {
    return <Login />;
  }

  return children;
};

const LoginRoute = () => {
  const navigate = useNavigate();
  const onLoginSuccess = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return <Login onLoginSuccess={onLoginSuccess} />;
};

export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <RequireAuth>
          <AppLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Businesses />} />

      <Route path="/admin/settings" element={<Settings />} />
      <Route
        path="/admin/businesses/:businessId/settings"
        element={<Settings />}
      />

      <Route path="/admin/businesses" element={<Businesses />} />
      <Route
        path="/admin/businesses/:businessId"
        element={<BusinessDetail />}
      />

      <Route path="/admin/businesses/:businessId/venues" element={<Venues />} />
      <Route
        path="/admin/businesses/:businessId/venues/:venueId"
        element={<VenueDetail />}
      />

      <Route path="/admin/businesses/:businessId/users" element={<Users />} />
      <Route
        path="/admin/businesses/:businessId/users/:userId"
        element={<UserDetail />}
      />
      <Route
        path="/admin/businesses/:businessId/users/update/:userId"
        element={<UserUpdate />}
      />

      <Route
        path="/admin/businesses/:businessId/members"
        element={<Members />}
      />
      <Route
        path="/admin/businesses/:businessId/members/:userId"
        element={<MemberDetail />}
      />

      <Route
        path="/admin/businesses/:businessId/apikeys"
        element={<Apikeys />}
      />
      <Route
        path="/admin/businesses/:businessId/apikeys/:apikeyId"
        element={<ApikeyDetail />}
      />
      <Route path="/sessions" element={<Sessions />} />

      {/* <Route path="/admin/users" element={<Users />} /> */}
      {/* <Route path="/admin/users/new" element={<UserDetail create={true} />} />
      <Route path="/admin/users/:uid" element={<UserDetail />} /> */}

      {/* <Route path="/admin/members" element={<Members />} />
      <Route path="/admin/members/:memberId" element={<MemberDetail />} /> */}
    </Route>
    <Route
      path="/admin/change-password"
      element={
        <RequireAuth>
          <ChangePassword />
        </RequireAuth>
      }
    />
    <Route path="/login" element={<LoginRoute />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  </Routes>
);
