import { Formik } from "formik";
import React from "react";
import { InnerFormUserUpdate } from "./InnerForm";
import { UserUpdateSchema } from "./schema";
import { UserUpdateFormProps, UserUpdateFormValues } from "./types";

export const UpdateUserForm = ({
  initialValue,
  submitHandler,
  cancelHandler,
}: UserUpdateFormProps) => {
  return (
    <Formik<UserUpdateFormValues>
      initialValues={initialValue}
      onSubmit={submitHandler}
      component={(props) => (
        <InnerFormUserUpdate {...props} cancelHandler={cancelHandler} />
      )}
      validationSchema={UserUpdateSchema}
    />
  );
};
