import { Button } from "@chakra-ui/button";
import { HStack } from "@chakra-ui/layout";
import { Auth, sendPasswordResetEmail } from "firebase/auth";
import { FormikErrors, FormikProps, withFormik } from "formik";
import React from "react";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { isEmptyStr } from "../util/stringHelper";

interface PasswordResetValues {
  email: string;
}

interface PasswordResetProps {
  onSuccess?: () => void;
  onError?: (message: string) => void;
  onCancel: () => void;
  firebaseAuth: Auth;
}

const InnerForm = ({
  isSubmitting,
  isValid,
  onCancel,
  values,
}: PasswordResetProps & FormikProps<PasswordResetValues>) => {
  return (
    <FormStack>
      <TextField
        name="email"
        label="Email"
        placeholder="Email Address"
        helperText="We will send you an email with instructions to reset your password."
      />

      <HStack width="100%" justifyContent="center" spacing="3" pt="4">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
          paddingLeft="4"
          paddingRight="4"
          minWidth="100px"
        >
          Reset password
        </Button>
        <Button
          disabled={isSubmitting || !isValid}
          paddingLeft="4"
          paddingRight="4"
          minWidth="100px"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </HStack>
    </FormStack>
  );
};

// Maps firebase error codes to login form validation messages.
const firebaseErrorCodeMap: {
  [key: string]: Partial<{ [P in keyof PasswordResetValues]: string }>;
} = {
  "auth/invalid-email": { email: "Invalid email address." },
  "auth/user-not-found": { email: "User not found." },
  "auth/user-disabled": { email: "This account has been disabled." },
  "auth/too-many-requests": { email: "Too many attempts. Try again later." },
};

const sendResetEmail = (
  auth: Auth,
  email: string
): Promise<
  { ok: true } | { ok: false; errorCode: string; errorMessage: string }
> => {
  return new Promise((resolve) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        resolve({ ok: true });
      })
      .catch((error) => {
        resolve({
          ok: false,
          errorCode: error.code,
          errorMessage: error.message,
        });
      });
  });
};

export const PasswordResetForm = withFormik<
  PasswordResetProps,
  PasswordResetValues
>({
  mapPropsToValues: (props) => {
    return {
      email: "",
    };
  },

  handleSubmit: async (
    values,
    { props, setFieldError, setErrors, setFieldValue }
  ) => {
    if (isEmptyStr(values.email)) {
      setFieldError("email", "Email is required");
      return;
    }

    const result = await sendResetEmail(props.firebaseAuth, values.email);
    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess();
      }
    } else {
      const formErrors = firebaseErrorCodeMap[result.errorCode];
      if (formErrors) {
        setErrors(formErrors);
      } else {
        console.error(`Firebase error: ${result.errorMessage}`);
        if (props.onError) {
          props.onError(
            "An error was ecountered while resetting your password."
          );
        }
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: async (values, props) => {
    let errors: FormikErrors<PasswordResetValues> = {};
    return errors;
  },
})(InnerForm);
