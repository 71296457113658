import { extendTheme } from "@chakra-ui/react";
import { ThemeConfig, theme as baseTheme } from "@chakra-ui/theme";
import { Button } from "./button/button";
import { Table } from "./table/table";
import { Input } from "./input/input";

// Import fonts
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/fredoka-one/400.css";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      "html, body": {
        margin: 0,
        padding: 0,
        color: "gray.600",
        // backgroundColor: "cherryUi.800",
        backgroundColor: "#f7f7f7",
        overflowX: "hidden",
      },
      "h1, h2, h3, h4, h5, h6": {
        color: props.colorMode === "light" ? "gray.600" : "white",
      },
    }),
  },
  colors: {
    black: "#000",
    white: "#fff",
    cherryGrey: {
      40: "#FAFAFA",
      150: "#EEEEEE",
      160: "#EAEAEA",
      300: "#DDDDDD",
      600: "#AAAAAA",
      700: "#999999",
      900: "#666666",
      1000: "#555555",
    },
    // Color scheme for cherryhub buttons
    cherryButton: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#ee3443", // bg
      600: "#bb2834", // hoverBg
      700: "#6e171e", // activeBg
    },
    cherryButtonDark: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#9e1029", // bg
      600: "#6b0a1b", // hoverBg
      700: "#510815", // activeBg
    },
    cherryUi: {
      600: "#c41836",
      700: "#9e1029",
      800: "#7a121e",
    },
  },
  fonts: {
    heading: "Poppins, Helvetica, sans-serif",
    body: "Poppins, Helvetica, sans-serif",
    jsonTextArea: "SFMono-Regular, Menlo, Monaco, Consolas, monospace",
  },
  components: {
    List: {
      baseStyle: {
        item: {
          fontSize: "sm",
        },
      },
    },
    Button,
    Table,
    Input,
    Checkbox: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Header: {
      baseStyle: {
        fontWeight: "600",
      },
      variants: {
        content: {
          color: "blue",
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          backgroundColor: "cherryUi.600",
          color: "#fff",
          borderTopRightRadius: "var(--chakra-radii-md)",
          borderTopLeftRadius: "var(--chakra-radii-md)",
        },
        body: {
          paddingTop: "var(--chakra-space-6)",
        },
        closeButton: {
          color: "#fff",
        },
      },
    },
    Divider: {
      variants: {
        "horizontal-divider": {
          backgroundColor: "#BABABA",
          width: "1.5px",
        },
        section: {
          borderBottomWidth: "1px",
          borderStyle: "solid",
          borderColor: "cherryGrey.150",
          opacity: "1",
          marginBottom: "0.5rem",
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "lg",
      },
    },
    Breadcrumb: {
      variants: {
        pageHeading: {
          container: {
            marginBottom: "2",
          },
          item: {
            color: "cherryUi.600",
            fontSize: "sm",
          },
          separator: {
            marginInlineStart: "0.25rem",
            marginInlineEnd: "0.25rem",
          },
        },
      },
    },
    Tag: {
      variants: {
        cherryButton: {
          container: {
            height: "2.5rem",
            paddingInlineStart: "4",
            paddingInlineEnd: "4",
            borderRadius: "full",
            color: "var(--chakra-colors-gray-800)",
            background: "var(--chakra-colors-gray-100)",
          },
        },
      },
    },
    Accordion: {
      variants: {
        section: {
          container: {
            border: "1px solid var(--chakra-colors-cherryGrey-150)",
          },
          panel: {
            paddingTop: "1rem",
            paddingBottom: "0.75rem",
          },
          button: {
            color: "white",
            background: "var(--chakra-colors-cherryGrey-700)",
            _expanded: {
              background: "var(--chakra-colors-cherryUi-600)",
            },
            _hover: {
              background: "var(--chakra-colors-cherryGrey-600)",
            },
          },
          icon: {
            color: "var(--chakra-colors-cherryGrey-1000)",
          },
        },

        "detail-section": {
          container: {
            border: "1px solid var(--chakra-colors-cherryGrey-150)",
          },
          panel: {
            paddingTop: "1rem",
            paddingBottom: "0.75rem",
          },
          button: {
            color: "cherryGrey.900",
            background: "cherryGrey.160",
            _hover: {
              background: "cherryGrey.150",
            },
          },
          icon: {
            color: "cherryGrey.900",
          },
        },

        "custom-field": {
          container: {
            border: "1px solid var(--chakra-colors-cherryGrey-300)",
            bgColor: "cherryGrey.40",
            borderRadius: "1rem",
          },
          panel: {
            padding: "1.5rem",
          },
          button: {
            borderRadius: "1rem",
            padding: "1.5rem",
            height: "4rem",
            color: "cherryGrey.900",
            _hover: {
              background: "cherryGrey.50",
            },
          },
          icon: {
            color: "cherryGrey.900",
          },
        },
      },
    },
    Tabs: {
      variants: {
        edit: {
          tab: {
            _selected: { color: "cherryGrey.900", bg: "white" },
            borderTopLeftRadius: "md",
            borderTopRightRadius: "md",
            bgColor: "cherryGrey.160",
            color: "cherryGrey.1600",
            px: 12,
            boxShadow: "md",
          },
          tablist: {
            px: 4,
            borderBottom: "none",
          },
          tabpanels: {
            boxShadow: "md",
            borderRadius: "lg",
            px: 4,
            pt: 8,
            pb: 10,
            bgColor: "white",
            position: "relative",
            zIndex: 99,
          },
        },
      },
    },
  },
});
