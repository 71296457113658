import { CherryHubDataApi } from "./dataApiModels";
import axios, { Axios, AxiosResponse } from "axios";
import { User } from "firebase/auth";

export interface ApiClient {}

export type ApiError = { statusCode?: number; message: string; ok: false };
export type ApiResult<T> =
  | {
      statusCode: number;
      data: T;
      ok: true;
      next?: string;
      continuationToken?: string;
    }
  | ApiError;

export const apiResult = <T>(
  axiosResponse: AxiosResponse,
  getData?: (data: any) => T
): ApiResult<T> => {
  if (axiosResponse.status >= 200 && axiosResponse.status <= 299) {
    // If the response body is paginated we'll find a url for the next page.
    // change approach to pass continuation token instead of relative url, there's an issue with encoding url with date/time
    const next = axiosResponse.data?._links?.next?.relativeUrl ?? undefined;
    const continuationToken =
      axiosResponse.data?._links?.next?.continuationToken ?? undefined;

    return {
      data: (getData ? getData(axiosResponse.data) : axiosResponse.data) as T,
      statusCode: axiosResponse.status,
      ok: true,
      next,
      continuationToken,
    };
  } else if (axiosResponse.status) {
    return {
      statusCode: axiosResponse.status,
      message:
        axiosResponse.data?.ErrorMessage ??
        `HTTP ${axiosResponse.status} error.`,
      ok: false,
    };
  } else {
    return {
      statusCode: axiosResponse.status,
      message: `Unknown error.`,
      ok: false,
    };
  }
};

export class DataApiClient implements ApiClient {
  dataApiClient: Axios;

  constructor(user: User) {
    this.dataApiClient = axios.create({
      baseURL: `${API_BASE_URL}${this.getPrefixRoute()}`,
      // HTTP errors will not throw exceptions
      validateStatus: () => true,
    });

    this.dataApiClient.interceptors.request.use((config) => {
      // Fetches the current access token. If it has expired or is close to exiring a new token will be fetched.
      return user.getIdTokenResult().then((idToken) => {
        if (!config.headers) {
          config.headers = {};
        }
        config.headers!.Authorization = `Bearer ${idToken.token}`;
        return config;
      });
    });
  }

  async getIp(): Promise<any> {
    const result = await this.dataApiClient.get(
      "https://api.ipify.org/?format=json"
    );
    return result;
  }

  getPrefixRoute(): string {
    // return "";
    return "/data-api";
  }

  async getEnvironment(): Promise<ApiResult<CherryHubDataApi.Environment>> {
    const result = await this.dataApiClient.get("/sysadmin/environment");
    return apiResult<CherryHubDataApi.Environment>(result);
  }

  async getOutboundIpDetails(): Promise<ApiResult<[key: string]>> {
    const result = await this.dataApiClient.get(
      "/sysadmin/environment/outbound-ip-details"
    );
    return apiResult<[key: string]>(result);
  }

  async getConfigurations(): Promise<
    ApiResult<CherryHubDataApi.Configurations>
  > {
    const result = await this.dataApiClient.get("/sysadmin/configuration");
    return apiResult<CherryHubDataApi.Configurations>(result);
  }

  async reloadConfigurations(): Promise<
    ApiResult<CherryHubDataApi.ScalarValue>
  > {
    const result = await this.dataApiClient.post(
      "/sysadmin/configuration/reload"
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async validateConfigurations(): Promise<
    ApiResult<CherryHubDataApi.ScalarValue>
  > {
    const result = await this.dataApiClient.get(
      "/sysadmin/configuration/validate"
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async refreshDocumentCache(): Promise<
    ApiResult<CherryHubDataApi.ScalarValue>
  > {
    const result = await this.dataApiClient.post(
      "/sysadmin/document-cache/refresh"
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async getLogEventLevel(): Promise<ApiResult<CherryHubDataApi.ScalarValue>> {
    const result = await this.dataApiClient.get(
      "/sysadmin/logging/log-event-level"
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async setLogEventLevel(
    eventLevel: string
  ): Promise<ApiResult<CherryHubDataApi.ScalarValue>> {
    const result = await this.dataApiClient.post(
      "/sysadmin/logging/log-event-level",
      JSON.stringify(eventLevel),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async resetLogEventLevel(): Promise<ApiResult<CherryHubDataApi.ScalarValue>> {
    const result = await this.dataApiClient.post(
      "/sysadmin/logging/log-event-level/reset"
    );
    return apiResult<CherryHubDataApi.ScalarValue>(result);
  }

  async getASPCExpirationStatus(): Promise<
    ApiResult<CherryHubDataApi.APSCExpirationStatus>
  > {
    const result = await this.dataApiClient.get(
      "/sysadmin/apple-pass-signing-certificates"
    );
    return apiResult<CherryHubDataApi.APSCExpirationStatus>(result);
  }

  async initApplePassKit(): Promise<ApiResult<string[]>> {
    const result = await this.dataApiClient.post(
      "/sysadmin/apple-pass-signing-certificates/init-digital-membership-service-apple-passkit"
    );
    return apiResult<string[]>(result);
  }

  async testApplePassCertificate(
    password: string,
    file: File
  ): Promise<ApiResult<unknown>> {
    const formData = new FormData();
    formData.append("file", file);
    const result = await this.dataApiClient.post(
      "/sysadmin/apple-pass-signing-certificates/test-pkcs12-certificate",
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
          "Certificate-Password": password,
        },
      }
    );
    return apiResult<unknown>(result);
  }
}
