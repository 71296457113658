import React, { useContext, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { useParams } from "react-router";
import { CherryHubAdminApi } from "../api/adminApiModels";
import { ErrorMessage } from "../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { ApiRequestState, useAdminApiRequest } from "../hooks/useApiRequest";
import { useAdminApiClient } from "../hooks/useApiClient";

interface TBusinessContext {
  business: any;
}

const BusinessContext = createContext<TBusinessContext | null>(null);

/**
 * Loads models from the api based on the presence of react-router path parameters. Example: if
 * the `businessId` path parameter is set, this component will display loading & error states and
 * then inject the business into the context once loaded.
 */
export const BusinessContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const apiClient = useAdminApiClient();
  const { businessId: originalBusinessId } = useParams();
  const [business, setBusiness] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const businessId: any = useMemo(() => {
    return originalBusinessId === "new" ? null : originalBusinessId;
  }, [originalBusinessId]);

  const loadBusiness = async () => {
    setIsLoading(true);
    if (!businessId) {
      setBusiness(null);
      setIsLoading(false);
      return;
    }
    const singleBusiness = await apiClient.getSingleBusiness(
      businessId?.toString() ?? ""
    );
    localStorage.setItem("contextBusinessId", singleBusiness.data._id);
    setBusiness(singleBusiness);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    loadBusiness();
  }, [businessId]);

  useEffect(() => {
    if (!business) {
      localStorage.removeItem("contextBusinessId");
    }
  }, [business]);

  const context = useMemo(
    () => ({
      business: business,
    }),
    [business]
  );

  // if (isLoading) {
  //   return <LoadingIndicator color="cherryUi.600" />;
  // }

  if (error) {
    return (
      <ErrorMessage>
        An error was encounterd while loading the page
      </ErrorMessage>
    );
  }

  return (
    <BusinessContext.Provider value={context}>
      {children}
    </BusinessContext.Provider>
  );
};

/**
 * Returns the business loaded from the `:businessId` path parameter
 */
export const useBusiness = (): any => {
  const ctx = useContext(BusinessContext);
  // console.log(ctx, "ctx");
  // if (!ctx || !ctx.business.data) {
  //   // throw new Error("Missing business context");
  //   return null;
  // }

  return ctx?.business?.data;
};
