import { Button } from "@chakra-ui/button";
import { SettingsIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/layout";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useProfile } from "../../context/ProfileContext";
import { BackIcon, CogIcon } from "../../styles/icons";

export interface MenuItemProps {
  title: string;
  target: string;
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  children?: React.ReactNode;
}

export const DesktopSubMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <AccordionPanel pb={4} p="0">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        {icon}
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

export const DesktopMenuItem = ({
  children,
  title,
  target,
  icon,
}: MenuItemProps) => (
  <AccordionItem bgColor="black" border="none">
    <h2>
      <AccordionButton>
        <Button
          as={Link}
          to={target}
          borderRadius="none"
          flex="1"
          textAlign="left"
          background="none"
          color="white"
          justifyContent="left"
          _hover={{ bgColor: "transparent" }}
          _focus={{ bgColor: "transparent" }}
        >
          <Box as="span" display="block" color="white" fontSize="sm" mr={2}>
            {icon}
          </Box>
          {title}
        </Button>
        {children && <AccordionIcon color="white" />}
      </AccordionButton>
    </h2>
    {children}
  </AccordionItem>
);

export const MobileSubMenuItem = ({ title, target, icon }: MenuItemProps) => (
  <AccordionPanel pb={4} p="0" border="none">
    <Button
      as={Link}
      to={target}
      w="100%"
      height="50px"
      justifyContent="start"
      paddingTop="4"
      paddingBottom="4"
      paddingLeft="4"
      paddingRight="8"
      shadow="md"
      fontWeight="600"
      borderRadius="none"
      fontSize="sm"
      color="cherryUi.800"
    >
      <Box as="span" display="block" color="cherryUi.800" fontSize="sm" mr={2}>
        {icon}
      </Box>
      {title}
    </Button>
  </AccordionPanel>
);

export const MobileMenuItem = ({
  children,
  title,
  target,
  icon,
}: MenuItemProps) => (
  <AccordionItem bgColor="cherryUi.700">
    <h2>
      <AccordionButton>
        <Button
          as={Link}
          to={target}
          borderRadius="none"
          flex="1"
          textAlign="left"
          background="none"
          color="white"
          justifyContent="left"
          _hover={{ bgColor: "transparent" }}
        >
          <Box as="span" display="block" color="white" fontSize="sm" mr={2}>
            {icon}
          </Box>
          {title}
        </Button>
        {children && <AccordionIcon color="white" />}
      </AccordionButton>
    </h2>
    {children}
  </AccordionItem>
);

export const HomeMenu = ({ isDesktop }: { isDesktop: boolean }) => {
  const MenuItem = isDesktop ? DesktopMenuItem : MobileMenuItem;
  const SubMenuItem = isDesktop ? DesktopSubMenuItem : MobileSubMenuItem;
  const profileContext = useProfile();
  const location = useLocation();
  const isSettingsPage = useMemo(
    () => location.pathname.indexOf("/settings") > -1,
    [location]
  );

  return (
    <Box w="100%">
      <Box mt={5}></Box>
      <Text
        ms={8}
        flex="1"
        fontWeight="bold"
        color="#fff"
        fontSize="16px"
        cursor="pointer"
        borderBottom="1px solid #ccc"
        display="inline"
      >
        Global
      </Text>
      <Box mb={5}></Box>
      <Accordion allowToggle width="100%">
        {/* <MenuItem
          target="/"
          icon={<UsersConfigurationIcon boxSize="20px" />}
          title="Administrators"
        /> */}
        {profileContext.isWorldAdmin && !isSettingsPage && (
          <MenuItem
            target="/admin/settings"
            icon={<SettingsIcon boxSize="20px" />}
            title="Settings"
          />
        )}

        {profileContext.isWorldAdmin && isSettingsPage && (
          <MenuItem
            target="/"
            icon={<BackIcon boxSize="20px" />}
            title="Back"
          />
        )}

        <MenuItem
          target={"/sessions"}
          icon={<CogIcon boxSize="20px" />}
          title="Sessions"
        />
      </Accordion>
    </Box>
  );
};
