import React from "react";
import { FormikErrors, FormikProps, withFormik } from "formik";
import { HStack, Text, Divider } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { isEmptyStr, isStrongPassword } from "../util/stringHelper";
import { TextField } from "../components/fields/TextField/TextField";
import { FormStack } from "../components/FormStack/FormStack";
import { Auth, sendPasswordResetEmail } from "firebase/auth";

interface PasswordResetValues {
  newPassword: string;
  newPassword2: string;
}

interface PasswordChangeProps {
  doChangePassword: (newPassword: string) => any;
  onSuccess?: () => void;
  onError?: (message: string) => void;
  onCancel: () => void;
  firebaseAuth: Auth;
}

const InnerForm = ({
  isSubmitting,
  isValid,
  onCancel,
  values,
}: PasswordChangeProps & FormikProps<PasswordResetValues>) => {
  return (
    <FormStack>
      <Text>Please set a new password for your account.</Text>
      <Divider />
      <TextField
        type="password"
        name="newPassword"
        label="New Password"
        placeholder=""
      />

      <TextField
        type="password"
        name="newPassword2"
        label="Confirm Password"
        placeholder=""
      />

      <HStack width="100%" justifyContent="center" spacing="3" pt="4">
        <Button
          isLoading={isSubmitting}
          colorScheme="cherryButton"
          color="#fff"
          type="submit"
          disabled={isSubmitting || !isValid}
          paddingLeft="4"
          paddingRight="4"
          minWidth="100px"
        >
          Change password
        </Button>
        {/* <Button
          disabled={isSubmitting || !isValid}
          paddingLeft="4"
          paddingRight="4"
          minWidth="100px"
          onClick={onCancel}
        >
          Cancel
        </Button> */}
      </HStack>
    </FormStack>
  );
};

// Maps firebase error codes to login form validation messages.
const firebaseErrorCodeMap: {
  [key: string]: Partial<{ [P in keyof PasswordResetValues]: string }>;
} = {
  "auth/invalid-password": { newPassword: "Invalid password." },
  "auth/too-many-requests": {
    newPassword: "Too many attempts. Try again later.",
  },
};

const sendResetEmail = (
  newPassword: string
): Promise<
  { ok: true } | { ok: false; errorCode: string; errorMessage: string }
> => {
  return new Promise((resolve) => {
    resolve({ ok: true });
  });
};

export const PasswordChangeForm = withFormik<
  PasswordChangeProps,
  PasswordResetValues
>({
  mapPropsToValues: (props) => {
    return {
      newPassword: "",
      newPassword2: "",
    };
  },

  handleSubmit: async (
    values,
    { props, setFieldError, setErrors, setFieldValue }
  ) => {
    if (!isStrongPassword(values.newPassword)) {
      setFieldError(
        "newPassword",
        "Password should be a minimum of 8 characters long, and contain: one uppercase character, one lowercase character, one number and one special character."
      );
      return;
    }

    if (values.newPassword !== values.newPassword2) {
      setFieldError("newPassword2", "Password does not match.");
      return;
    }

    const result = await props.doChangePassword(values.newPassword);
    if (result.ok) {
      if (props.onSuccess) {
        props.onSuccess();
      }
    } else {
      const formErrors = firebaseErrorCodeMap[result.errorCode];
      if (formErrors) {
        setErrors(formErrors);
      } else {
        console.error(`Firebase error: ${result.errorMessage}`);
        if (props.onError) {
          props.onError(
            "An error was ecountered while resetting your password."
          );
        }
      }
    }
  },

  validateOnMount: false,
  validateOnBlur: true,

  validate: async (values, props) => {
    let errors: FormikErrors<PasswordResetValues> = {};
    return errors;
  },
})(InnerForm);
