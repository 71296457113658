import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";
import DateUtil from "../../util/DateUtil";

export const UserDetail = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });

  const { debouncedQuery: abnText, element: abnInputElement } = useSearchInput({
    placeholder: "Start typing...",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    modules: [],
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    // dummy
    // srcVenue: {
    //   Id: 34,
    //   Name: "Royal Venue 4",
    // },
  });
  const [businesses, setBusinesses] = useState<any>([]);
  const [abnRecords, setAbnRecords] = useState<any>([]);
  const [currentUser, setCurrentUser] = useState<any>();
  const [venueRecords, setVenueRecords] = useState<any>([]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { userId: originalUserId } = useParams();
  const userId: any = useMemo(() => {
    return originalUserId === "new" ? null : originalUserId;
  }, [originalUserId]);

  const load = async (skipLoader = false) => {
    if (!skipLoader) {
      setIsLoading(true);
    }
    const singleVenue = await apiClient.getSingleUser(userId?.toString() ?? "");
    setCurrentUser(singleVenue.data);
    setIsLoading(false);
    setIsSubmitted(false);
  };

  const areAllValid = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = formData.email && emailRegex.test(formData.email);
    return (
      formData.firstName && formData.lastName && formData.role && isEmailValid
    );
  };

  const onConfirm = (msg: string, callback: any) => {
    if (confirm(msg)) {
      callback();
    }
  };

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupVenues = async () => {
    setIsStepLoading(true);
    if (!lookupText) {
      setVenueRecords([]);
      return;
    }
    const records = await apiClient.getLookupVenues(lookupText);
    setVenueRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const toggleRole = () => {
    const ask =
      currentUser.meta.role === "Admin"
        ? "Are you sure you want to change this user's role to Staff?"
        : "Are you sure you want to change this user's role to Admin?";
    onConfirm(ask, async () => {
      setIsSubmitted(true);
      const result = await apiClient.toggleRole(userId);
      load(true);
    });
  };

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    if (!userId) {
      const result = await apiClient.createUser(formData);
      setIsLoading(true);

      if (result.status >= 200 && result.status <= 299) {
        navigate(
          BusinessUtil.getUrl(contextBusiness, `/users/${result.data._id}`)
        );
        showSuccessToast("User successfully added.");
      } else {
        setIsLoading(false);
        let errorDesc = "";
        try {
          if (result.data.error === "exists") {
            errorDesc = "That user already exists";
          }
          showErrorToast(errorDesc);
        } catch (e) {
          showErrorToast("An unknown error occurred");
        }
      }
    } else {
      const result = await apiClient.modifyVenue(userId, formData);
      navigate(BusinessUtil.getUrl(contextBusiness, `/venues`));
    }
    setIsSubmitted(false);
  }, [userId, formData]);

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, "/users"));
  };

  useEffect(() => {
    if (userId && contextBusiness) {
      load();
    }
  }, [userId, contextBusiness]);

  useEffect(() => {
    if (contextBusiness) {
      updateForm({
        role: contextBusiness?.isSystemRecord ? "Global" : "Staff",
      });
    }
  }, [contextBusiness]);

  useEffect(() => {
    if (userId) {
      if (currentUser) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [currentUser, userId]);

  if (isLoading || !contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>
          {currentUser
            ? currentUser.firstName + " " + currentUser.lastName
            : "New User"}
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {userId && currentUser && (
              <Box w="100%">
                <Text fontSize="md" mb={4}>
                  <strong>Email Address:</strong> {currentUser.email}
                  <br />
                  <strong>Created On:</strong>{" "}
                  {DateUtil.getLocalDateFormatFromString(currentUser.createdAt)}
                  <br />
                  <strong>Role:</strong> {currentUser.meta.role}
                </Text>
                <Divider />
              </Box>
            )}

            {/* creation form */}
            {!userId && (
              <Box w="100%" maxW="600px">
                <FormControl w="100%">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    w="100%"
                    type="text"
                    placeholder=""
                    onChange={(e) => updateForm({ firstName: e.target.value })}
                    value={formData.firstName}
                  />
                </FormControl>

                <FormControl w="100%" mt={4}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    w="100%"
                    type="text"
                    placeholder=""
                    onChange={(e) => updateForm({ lastName: e.target.value })}
                    value={formData.lastName}
                  />
                </FormControl>

                <FormControl w="100%" mt={4}>
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    w="100%"
                    type="email"
                    placeholder=""
                    onChange={(e) => updateForm({ email: e.target.value })}
                    value={formData.email}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Role</FormLabel>
                  <Select
                    value={formData.role}
                    onChange={(e) => updateForm({ role: e.target.value })}
                  >
                    {(contextBusiness.isSystemRecord
                      ? ["Global"]
                      : ["Admin", "Staff"]
                    ).map((item: any) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    )) || []}
                  </Select>
                </FormControl>
              </Box>
            )}

            {/* generic step loader */}
            {renderLoader()}
            <Box my="20px"></Box>
            <Box>
              {!userId && (
                <HStack>
                  <Box>
                    <Button
                      colorScheme="cherryButton"
                      type="submit"
                      isLoading={isSubmitted}
                      disabled={isStepLoading || !areAllValid()}
                      onClick={onSubmit}
                    >
                      Save
                    </Button>
                  </Box>

                  <Box>
                    <Button disabled={isStepLoading} onClick={onCancel}>
                      Cancel
                    </Button>
                  </Box>
                </HStack>
              )}

              {userId && currentUser && !currentUser.isSystemRecord && (
                <HStack>
                  <Box>
                    <Button
                      colorScheme="cherryButton"
                      type="submit"
                      isLoading={isSubmitted}
                      disabled={isStepLoading}
                      onClick={toggleRole}
                    >
                      {currentUser.meta.role === "Admin"
                        ? "Change role to Staff"
                        : "Change role to Admin"}
                    </Button>
                  </Box>

                  <Box>
                    <Button disabled={isStepLoading} onClick={onCancel}>
                      Back
                    </Button>
                  </Box>
                </HStack>
              )}
            </Box>
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
    </>
  );
};
