import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Switch,
  Tag,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useSearchInput } from "../../hooks/useSearchInput";
import { useToast } from "../../hooks/useToast";
import DateUtil from "../../util/DateUtil";

export const BusinessDetail = () => {
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const { debouncedQuery: lookupText, element: lookupInputElement } =
    useSearchInput({
      placeholder: "Start typing...",
    });

  const { debouncedQuery: abnText, element: abnInputElement } = useSearchInput({
    placeholder: "Start typing...",
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    modules: [],

    // dummy
    // srcBusiness: {
    //   Id: 4,
    //   Name: "ANGELEE TES",
    //   State: "NSW",
    // },
    // abn: {
    //   Abn: "86704605128",
    //   AbnStatus: "0000000001",
    //   IsCurrent: true,
    //   Name: "ANGELEE TES",
    //   NameType: "Entity Name",
    //   Postcode: "2209",
    //   Score: 97,
    //   State: "NSW",
    //   hasExistingBusiness: false,
    // },
  });
  const [businesses, setBusinesses] = useState<any>([]);
  const [abnRecords, setAbnRecords] = useState<any>([]);
  const [businessRecords, setBusinessRecords] = useState<any>([]);

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStepLoading, setIsStepLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { businessId: originalBusinessId } = useParams();
  const businessId: any = useMemo(() => {
    return originalBusinessId === "new" ? null : originalBusinessId;
  }, [originalBusinessId]);

  const onSelectBusinessRecord = async (businessRecord: any) => {
    updateForm({ srcBusiness: businessRecord });
  };
  const onSelectAbnRecord = async (abnRecord: any) => {
    updateForm({ abn: abnRecord });
  };

  const onConfirm = (msg: string, callback: any) => {
    if (confirm(msg)) {
      callback();
    }
  };

  const toggleModule = useCallback(
    (moduleName: string) => {
      let modules: string[] = formData.modules || [];
      if (modules.indexOf(moduleName) > -1) {
        modules = modules.filter((m) => m !== moduleName);
      } else {
        modules.push(moduleName);
      }
      updateForm({ modules });
    },
    [formData]
  );

  const updateForm = useCallback(
    (newData) => {
      setFormData({ ...formData, ...newData });
    },
    [formData]
  );

  const getLookupBusinesses = async () => {
    setIsStepLoading(true);
    setBusinessRecords([]);
    if (!lookupText) {
      setIsStepLoading(false);
      setBusinessRecords([]);
      return;
    }
    const records = await apiClient.getLookupBusinesses(lookupText);
    setBusinessRecords(records.data);
    setIsStepLoading(false);
  };

  const getAbnBusinesses = async () => {
    setIsStepLoading(true);
    setAbnRecords([]);
    if (!abnText) {
      return;
    }
    const records = await apiClient.getAbnBusinesses(abnText);
    setAbnRecords(records.data);
    setIsStepLoading(false);
  };

  const renderLoader = () => {
    return (
      isStepLoading && (
        <Box>
          <LoadingIndicator color="cherryUi.600" />
        </Box>
      )
    );
  };

  const onSubmit = useCallback(async () => {
    setIsSubmitted(true);
    if (!businessId) {
      const result = await apiClient.enableBusiness(formData);
      setIsLoading(true);

      if (result.status >= 200 && result.status <= 299) {
        navigate(`/admin/businesses/${result.data._id}`);
        showSuccessToast("Business successfully enabled.");
      } else {
        setIsLoading(false);
        let errorDesc = "";
        try {
          if (result.data.error === "exists") {
            errorDesc = "That business already exists";
          }
          showErrorToast(errorDesc);
        } catch (e) {
          showErrorToast("An unknown error occurred");
        }
      }
    } else {
      const result = await apiClient.modifyBusiness(businessId, formData);
      navigate(`/`);
    }
    setIsSubmitted(false);
  }, [businessId, formData]);

  const onCancel = () => {
    navigate(`/`);
  };

  useEffect(() => {
    // if (lookupText) {
    getLookupBusinesses();
    // }
  }, [lookupText]);

  useEffect(() => {
    if (abnText) {
      getAbnBusinesses();
    }
  }, [abnText]);

  useEffect(() => {
    if (contextBusiness) {
      updateForm({
        modules: contextBusiness.modules,
        isKybChecked: contextBusiness.isKybChecked,
      });
    }
  }, [contextBusiness]);

  useEffect(() => {
    if (businessId) {
      if (contextBusiness) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [contextBusiness, businessId]);

  if (isLoading) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>
          {contextBusiness
            ? contextBusiness?.srcBusiness?.name ?? contextBusiness?.title
            : "New Business"}
        </PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            {businessId && (
              <Box w="100%">
                <Text fontSize="md" mb={4}>
                  <strong>ID:</strong> {contextBusiness.srcBusiness?.id ?? "0"}
                  <br />
                  <strong>ABN:</strong> {contextBusiness.abn?.Abn ?? "N/A"}
                  <br />
                  <strong>Created On:</strong>{" "}
                  {DateUtil.getLocalDateFormatFromString(
                    contextBusiness.createdAt
                  )}
                </Text>
                <Divider />
              </Box>
            )}

            {/* step 1: business lookup */}
            {!businessId && (
              <Box>
                <Text>
                  <strong>Step 1:</strong> Find the business you wish to enable
                  from the ebet cloud:
                </Text>
                {formData.srcBusiness && (
                  <Tooltip label="Edit">
                    <Tag
                      cursor="pointer"
                      onClick={() =>
                        onConfirm("Do you want to edit the business?", () =>
                          updateForm({ srcBusiness: null })
                        )
                      }
                      size="lg"
                      mt={2}
                      bg="#444"
                      color="#fff"
                    >
                      {formData.srcBusiness.name} (ID: {formData.srcBusiness.id}
                      )
                    </Tag>
                  </Tooltip>
                )}
                {!formData.srcBusiness && (
                  <Box>
                    <Box mt={2} flex="1">
                      <Box width="100%">{lookupInputElement}</Box>
                    </Box>

                    <Box>
                      {(businessRecords ?? []).map((record: any, i: number) => (
                        <HStack
                          key={`${record.id}-${i}`}
                          w="100%"
                          padding="2"
                          border="1px solid #efefef"
                          backgroundColor="#f4f4f4"
                          borderRadius="md"
                          as="button"
                          alignItems="start"
                          textAlign="left"
                          onClick={() => onSelectBusinessRecord(record)}
                        >
                          <Box w="100%">
                            <Text
                              w="100%"
                              fontSize="md"
                              color="gray.700"
                              fontWeight="600"
                            >
                              {record.name}
                            </Text>
                          </Box>
                          <Text color="gray.600" fontSize="sm">
                            ID:&nbsp;{record.id}
                          </Text>
                        </HStack>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {/* step 2: abn lookup */}
            {!!formData.srcBusiness && !businessId && (
              <Box>
                <Text>
                  <strong>Step 2:</strong> Find and select the related ABN
                  record:
                </Text>
                {formData.abn && (
                  <Tooltip label="Edit">
                    <Tag
                      cursor="pointer"
                      onClick={() =>
                        onConfirm("Do you want to edit the ABN record?", () =>
                          updateForm({ abn: null })
                        )
                      }
                      size="lg"
                      mt={2}
                      bg="#444"
                      color="#fff"
                    >
                      {formData.abn.Name} ({formData.abn.Abn})
                    </Tag>
                  </Tooltip>
                )}
                {!formData.abn && (
                  <Box>
                    <Box mt={2} flex="1">
                      <Box width="100%">{abnInputElement}</Box>
                    </Box>

                    <Box>
                      {(abnRecords ?? []).map((record: any, i: number) => (
                        <HStack
                          key={`${record.Id}-${i}`}
                          w="100%"
                          padding="2"
                          border="1px solid #efefef"
                          backgroundColor="#f4f4f4"
                          borderRadius="md"
                          as="button"
                          alignItems="start"
                          textAlign="left"
                          onClick={() => onSelectAbnRecord(record)}
                        >
                          <Box w="100%">
                            <Text
                              w="100%"
                              fontSize="md"
                              color="gray.700"
                              fontWeight="600"
                            >
                              {record.Name}
                            </Text>
                          </Box>
                          <Text color="gray.600" fontSize="sm">
                            Abn:&nbsp;{record.Abn}
                          </Text>
                        </HStack>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {/* step 3: modules */}
            {/* {(!!formData.abn || businessId) &&
              !contextBusiness?.isSystemRecord && (
                <Box>
                  {!businessId && (
                    <Text>
                      <strong>Step 3:</strong> Select modules to enable for this
                      business:
                    </Text>
                  )}
                  {businessId && <Text>Enabled modules:</Text>}
                  <Box mt={3}>
                    <Flex>
                      <Switch
                        onChange={() => toggleModule("LinkIT")}
                        isChecked={formData.modules.indexOf("LinkIT") > -1}
                      >
                        LinkIT
                      </Switch>
                      {formData.modules.indexOf("LinkIT") > -1 && (
                        <Switch
                          ms={5}
                          isChecked={formData.isKybChecked}
                          onChange={(e) =>
                            updateForm({ isKybChecked: e.target.checked })
                          }
                        >
                          Is KYB done?
                        </Switch>
                      )}
                    </Flex>

                    <Flex mt="10px">
                      <Switch
                        onChange={() => toggleModule("TapIT")}
                        isChecked={formData.modules.indexOf("TapIT") > -1}
                      >
                        TapIT
                      </Switch>
                    </Flex>
                  </Box>
                </Box>
              )} */}

            {/* generic step loader */}
            {renderLoader()}
            <Box my="20px"></Box>
            {!contextBusiness?.isSystemRecord && (
              <Box>
                <Divider />
                <Box my="20px"></Box>
                <Box>
                  <HStack>
                    <Box>
                      <Button
                        colorScheme="cherryButton"
                        type="submit"
                        isLoading={isSubmitted}
                        disabled={isStepLoading}
                        onClick={onSubmit}
                      >
                        Save
                      </Button>
                    </Box>

                    <Box>
                      <Button disabled={isStepLoading} onClick={onCancel}>
                        Cancel
                      </Button>
                    </Box>
                  </HStack>
                </Box>
              </Box>
            )}
          </VStack>
        </Card>
        {error != null && (
          <ErrorMessage>
            An error was encountered while searching businesses.
          </ErrorMessage>
        )}
      </PageContent>
    </>
  );
};
