import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/input";
import {
  FormControlProps,
  InputGroupProps,
  StackProps,
} from "@chakra-ui/react";
import React from "react";
import { CheckIcon } from "../../../styles/icons";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface TextFieldProps extends BaseFieldProps {
  isValid?: boolean;
}

export const TextField = ({
  name,
  label,
  id,
  helperText,
  isValid,
  infoText,
  fcProps,
  valueProps,
  horizontal,
  ...inputProps
}: TextFieldProps & InputProps) => {
  return (
    <FieldWrapper
      name={name}
      id={id}
      label={label}
      helperText={helperText}
      infoText={infoText}
      horizontal={horizontal}
      fcProps={fcProps}
      valueProps={valueProps}
    >
      {({ field }) => (
        <InputGroup shadow="sm">
          <Input
            {...field}
            value={field.value == null ? "" : field.value}
            backgroundColor="white"
            {...inputProps}
          />
          {isValid && (
            <InputRightElement children={<CheckIcon color="green.400" />} />
          )}
        </InputGroup>
      )}
    </FieldWrapper>
  );
};
