import { Heading, VStack } from "@chakra-ui/layout";
import React from "react";
import { useLayoutContext } from "../../context/LayoutContext";

interface PageHeadingProps {
  children?: React.ReactNode;
}

const Title = ({ children }: { children: React.ReactNode }) => {
  return (
    <Heading
      as="h1"
      width="100%"
      color="black"
      fontWeight="600"
      mt="auto"
      mb="auto"
    >
      {children}
    </Heading>
  );
};

export const PageHeading = ({ children }: PageHeadingProps) => {
  const { isDesktop } = useLayoutContext();

  const paddingProps = isDesktop
    ? { paddingLeft: "40px", paddingRight: "40px" }
    : { paddingLeft: "2", paddingRight: "2" };

  return (
    <VStack
      {...paddingProps}
      spacing="2"
      paddingTop="4"
      paddingBottom="4"
      alignItems="start"
      color="white"
      minH="110px"
    >
      {children}
    </VStack>
  );
};

PageHeading.Title = Title;
