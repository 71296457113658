import React from "react";
import { Box, Heading } from "@chakra-ui/layout";

import icon from "assets/ebet-logo.png";
import { Image } from "@chakra-ui/image";
import { useLayoutContext } from "../../context/LayoutContext";

export const AppTitle = () => {
  const { isDesktop } = useLayoutContext();
  return (
    <Box flex="1">
      <Heading
        as="span"
        ml={isDesktop ? "2" : "2"}
        fontSize="20px"
        lineHeight="20px"
        color="#fff"
      >
        {isDesktop && (
          <Box
            display="inline-block"
            w="100px"
            // h="20px"
            textAlign="center"
            // bg="#ffffff"
            p={2}
          >
            <Image
              src={icon}
              w="80px"
              // h="80px"
              // position="absolute"
              alt="Cherryhub icon"
              zIndex="2"
              // borderRadius="full"
              shadow="lg"
            />
          </Box>
        )}
        {!isDesktop && <>ebet AdminIT</>}
      </Heading>
    </Box>
  );
};
