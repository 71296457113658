import React from "react";
import { useUserContext } from "../../context/UserContext";
import { LogoutIcon } from "../../styles/icons";
import { HStack, Box } from "@chakra-ui/layout";
import { IconButton, Text } from "@chakra-ui/react";
import { useBusiness } from "../../context/BusinessContext";
import { useNavigate } from "react-router-dom";
import BusinessUtil from "../../util/BusinessUtil";
import { useProfile } from "../../context/ProfileContext";

export const CurrentUser = () => {
  const { user, logout } = useUserContext();
  const contextBusiness = useBusiness();
  const profileContext = useProfile();

  const navigate = useNavigate();

  if (!user) {
    return null;
  }

  return (
    <HStack>
      <Box>
        <Text
          flex="1"
          fontWeight="bold"
          color="#fff"
          fontSize="16px"
          cursor="pointer"
          onClick={() => {
            if (contextBusiness) {
              navigate(BusinessUtil.getUrl(contextBusiness, "/"));
            } else {
              navigate("/");
            }
          }}
        >
          {profileContext.meta.role}
        </Text>
        <Text flex="1" color="#fff" fontSize="14px">
          {user.displayName ?? user.email}
        </Text>
      </Box>
      <IconButton
        borderRadius="full"
        icon={<LogoutIcon />}
        aria-label="Logout"
        color="#fff"
        colorScheme="cherryButton"
        onClick={logout}
        title="Logout"
      />
    </HStack>
  );
};
