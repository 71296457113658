import React from "react";
import { Icon, IconProps } from "@chakra-ui/icons";
import {
  FaCircle,
  FaCog,
  FaCreditCard,
  FaGift,
  FaList,
  FaUserFriends,
  FaUserLock,
  FaWpforms,
  FaUsersCog,
  FaBriefcase,
  FaArrowLeft,
} from "react-icons/fa";
import {
  FiUser,
  FiUserPlus,
  FiLock,
  FiMoreHorizontal,
  FiPlus,
  FiLogOut,
  FiX,
  FiCheck,
  FiTrash2,
  FiEdit,
  FiRefreshCw,
  FiCreditCard,
  FiSmartphone,
  FiRepeat,
  FiZap,
  FiCheckCircle,
  FiXCircle,
  FiInfo,
  FiFileText,
  FiSearch,
  FiSettings,
  FiUsers,
  FiBook,
  FiBookOpen,
  FiColumns,
} from "react-icons/fi";
import { TbApps, TbBookDownload } from "react-icons/tb";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { ImHome } from "react-icons/im";
import {
  IoLogoAppleAppstore,
  IoEllipsisHorizontalOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { MdOutlineFilterList, MdOutlineFilterListOff } from "react-icons/md";
import { GrDocumentTime, GrDocumentTest, GrIntegration } from "react-icons/gr";
import { RiShareBoxFill } from "react-icons/ri";
import { FaClipboardUser } from "react-icons/fa6";
import { IconType } from "react-icons/lib";

/**
 * Wraps a non Chakra icon in a <Icon/> component
 * @param icon
 * @returns
 */
const wrapIcon = (icon: IconType) => {
  return (props: IconProps) => <Icon {...props} as={icon} />;
};

export const BackIcon = wrapIcon(FaArrowLeft);

export const BriefcaseIcon = wrapIcon(FaBriefcase);

export const CogIcon = wrapIcon(FaCog);

export const CircleIcon = wrapIcon(FaCircle);

export const ListIcon = wrapIcon(FaList);

export const UserIcon = wrapIcon(FiUser);

export const UsersIcon = wrapIcon(FaUserFriends);

export const UserPlusIcon = wrapIcon(FiUserPlus);

export const UserLockIcon = wrapIcon(FaUserLock);

export const LockIcon = wrapIcon(FiLock);

export const MoreHorizontal = wrapIcon(FiMoreHorizontal);

export const PlusIcon = wrapIcon(FiPlus);

export const LogoutIcon = wrapIcon(FiLogOut);

export const DeleteIcon = wrapIcon(FiX);

export const EditIcon = wrapIcon(FiEdit);

export const CheckIcon = wrapIcon(FiCheck);

export const TrashIcon = wrapIcon(FiTrash2);

export const RefreshIcon = wrapIcon(FiRefreshCw);

export const CardIcon = wrapIcon(FiCreditCard);

export const PhoneIcon = wrapIcon(FiSmartphone);

export const TransferIcon = wrapIcon(FiRepeat);

export const ZapIcon = wrapIcon(FiZap);

export const CheckCircleIcon = wrapIcon(FiCheckCircle);

export const XCircleIcon = wrapIcon(FiXCircle);

export const InfoIcon = wrapIcon(FiInfo);

export const FileIcon = wrapIcon(FiFileText);

export const CreditCardIcon = wrapIcon(FaCreditCard);

export const GiftCardIcon = wrapIcon(FaGift);

export const BoxShareIcon = wrapIcon(RiShareBoxFill);

export const SearchIcon = wrapIcon(FiSearch);

export const DocumentTime = wrapIcon(GrDocumentTime);

export const AppstoreIcon = wrapIcon(IoLogoAppleAppstore);

export const TestDocumentIcon = wrapIcon(GrDocumentTest);

export const HomeIcon = wrapIcon(ImHome);

export const VenueIcon = wrapIcon(HiOutlineBuildingStorefront);

export const GearIcon = wrapIcon(FiSettings);

export const IntegrationIcon = wrapIcon(GrIntegration);

export const FormIcon = wrapIcon(FaWpforms);

export const MembershipRenewalIcon = wrapIcon(FiUsers);

export const BookIcon = wrapIcon(FiBook);

export const BookOpenIcon = wrapIcon(FiBookOpen);

export const ColumnIcon = wrapIcon(FiColumns);

export const PlatformApplicationsIcon = wrapIcon(TbApps);

export const UsersConfigurationIcon = wrapIcon(FaUsersCog);

export const EllipsisIcon = wrapIcon(IoEllipsisHorizontalOutline);

export const FilterIcon = wrapIcon(MdOutlineFilterList);

export const ClearFilterIcon = wrapIcon(MdOutlineFilterListOff);

export const CloseCircleIcon = wrapIcon(IoCloseCircleOutline);

export const MemberIcon = wrapIcon(FaClipboardUser);

export const DownloadDocumentIcon = wrapIcon(TbBookDownload);
