import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";
import { UpdateUserForm } from "../../forms/user-update";
import { UserUpdateFormValues } from "../../forms/user-update/types";
import { useAdminApiClient } from "../../hooks/useApiClient";
import { useToast } from "../../hooks/useToast";
import BusinessUtil from "../../util/BusinessUtil";
import { Result } from "../../util/result";

export const UserUpdate = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { showSuccessToast, showErrorToast } = useToast();
  const contextBusiness = useBusiness();
  const apiClient = useAdminApiClient();
  const [formData, setFormData] = useState<any>({
    firstName: "",
    lastName: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadUser = async () => {
    setIsLoading(true);
    const singleVenue = await apiClient.getSingleUser(userId?.toString() ?? "");
    setFormData({
      firstName: singleVenue?.data?.firstName ?? "",
      lastName: singleVenue?.data?.lastName ?? "",
    });
    setIsLoading(false);
  };

  const onSubmit = async (value: UserUpdateFormValues) => {
    const result: Result<any> = await apiClient.updateUserProfile(
      userId!,
      value
    );
    if (result.isSuccess) {
      navigate(BusinessUtil.getUrl(contextBusiness, `/users`));
      showSuccessToast("User successfully updated.");
    } else {
      showErrorToast("Failed to update user profile");
    }
  };

  const onCancel = () => {
    navigate(BusinessUtil.getUrl(contextBusiness, "/users"));
  };

  useEffect(() => {
    if (userId && contextBusiness) {
      loadUser();
    }
  }, [userId, contextBusiness]);

  if (isLoading || !contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Update User</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <UpdateUserForm
            initialValue={formData}
            submitHandler={onSubmit}
            cancelHandler={onCancel}
          />
        </Card>
      </PageContent>
    </>
  );
};
